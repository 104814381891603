<template>
  <div class="main">
    <!-- 头部 -->
    <top></top>
    <!-- 导航 -->
    <navigation> </navigation>

    <div class="search-bgc">
      <div class="search-box">
        <el-input v-model="title" placeholder="请输入内容" suffix-icon="el-icon-search" @change="search(1)"></el-input>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <ul class="list">
        <li v-for="li in tableData" :key="li.id" class="item" @click="toDetail(li.id)">
          <div class="headline">· {{li.title}}</div>
          <div class="date">{{li.createTime}}</div>
        </li>
      </ul>
      <div class="block">
        <el-pagination
          :current-page.sync="pageIndex"
          :total="totalElements"
          :page-sizes="pageSizeList"
          @current-change="changePage"
          @size-change="pageSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>

    <!-- 底部 -->
    <bottom></bottom>
  </div>
</template>

<script>
import Bottom from "../components/Bottom.vue";
import Navigation from "../components/Navigation.vue";
import Top from "../components/Top.vue";
import newsApi from "@/api/news";

export default {
  components: {
    Navigation,
    Top,
    Bottom,
  },
  data() {
    return {
      title: "",
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      pageSizeList: [10, 20, 30],
    };
  },
  methods: {
    search(pageIndex) {
      if(this.title != "") {
        this.changePage(pageIndex);
      }
    },
    changePage(pageIndex) {
      var self = this;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);
      formData.append("title", self.title);

      newsApi.pageList(formData).then(function (response) {
        var jsonData = response.data.data;

        self.tableData = jsonData.data;
        self.totalPages = jsonData.totalPages;
        self.totalElements = jsonData.recordsTotal;
      })
      .catch((error) => {
        self.$message.error("新闻获取数据发生错误！");
      });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    toDetail(id) {
      let pathInfo = this.$router.resolve({
      path:'/detail',
        query:{
            id: id
        }
      })
      window.open(pathInfo.href, '_blank');
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-width: 1139px;
  width: 100%;
}

.search-bgc {
  background: url(../assets/img/breadcrumb.png);
  background-repeat: no-repeat;
  background-size: 43.6% 93.2%;
  background-position: 90% 100%;
  background-color: rgba(233, 234, 236, 1);
  padding: 36px 0;
  .search-box {
	  font-family: Roboto;
    width: 640px;
    margin: 0 auto;
    /deep/.el-input__icon {
      color: #1f4a99;
      font-size: 20px;
    }
  }
}
// 内容
.content {
  
  max-width: 976px;
  margin: 40px auto;
  min-height: calc(100vh - 150px);

  .list {
	  font-family: 'Regular';
    cursor: pointer;
    margin-bottom: 30px;
    .item {
      display: flex;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid rgba(232, 232, 232, 1);
      .headline {
        width: 600px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .highlight {
          color: #ff4a2d;
        }
      }
    }
  }
  .block {
     display: flex;
	 justify-content: center;
    /deep/.el-pager li.active {
      border: 1px solid rgba(232, 232, 232, 1) !important;
      color: #777777;
    }
    /deep/.el-pager li {
      color: #777777;
	  
    }
  }
}
</style>
